// main.js サイトで利用するスクリプトを記載
// ./core/ jQuery本体（異なるバージョンを利用したい場合はcore内のライブラリを差し替えてください）
// ./app/ 各種プラグイン各種を格納

// ここからは自由に
$(".menu").on("click", function() {
  $(".menu").toggleClass("menuopen");
  $(".mask").toggleClass("menuopen");
});
$(".js-smooth-scroll").on("click", function() {
  $(".menu").removeClass("menuopen");
  $(".mask").removeClass("menuopen");
});

//スムーススクロール
/* eslint-disable */
var scroll = new SmoothScroll('a[href*="#"]');
/* eslint-enable */

// TOPへ戻るボタン
var pagetop = $('.pagetop');
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    pagetop.fadeIn();
  } else {
    pagetop.fadeOut();
  }
});
pagetop.click(function () {
  $('body, html').animate({ scrollTop: 0 }, 500);
  return false;
});

const es6Name = 'john';
console.log(`hello ${es6Name} . nice to meet you`);
